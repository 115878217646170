'use client';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import { saveSearchQuery } from '@util/firestore/search';
import useDebounce from '@util/hooks/useDebounce';
import useSearchHistory from '@util/hooks/useSearchHistory';
import { useAuth } from 'context/AuthContext';
import { useSearch } from 'context/SearchContext';
import { useQuerySuggestions } from 'features/search';
import type { BaseHit } from 'instantsearch.js';
import { Hit as SearchHit } from 'instantsearch.js';
import type { SendEventForHits } from 'instantsearch.js/es/lib/utils';
import { MotionDiv } from 'motion';
import Link from 'next/link';
import { useEffect } from 'react';
import {
  Highlight,
  HitsProps,
  Index,
  useHits,
  useSearchBox,
} from 'react-instantsearch-hooks-web';

// HACK: some very odd wizardry to be able to create a CustomHits component with non-optional hitComponent
//       perhaps refactor this somewhere else along w/ its imports.
type CustomHitsProps<THit extends BaseHit> = HitsProps<THit> &
  Omit<HitsProps<THit>, 'hitComponent'> & {
    hitComponent: React.JSXElementConstructor<{
      hit: THit;
      sendEvent: SendEventForHits;
    }>;
  };

export function LastSearchSuggestions({
  searchHistory,
  setShowSearchPreview,
}: {
  searchHistory: string[];
  setShowSearchPreview: (val: boolean) => void;
}) {
  const { userDoc } = useAuth();

  return (
    <div className="min-w-[38rem] max-w-[48rem] pr-[3rem] lg:border-r">
      <div className="mt-[1rem] flex flex-col lg:ml-[9rem]">
        <div className="flex flex-col gap-y-2">
          {searchHistory.length > 0 ? (
            searchHistory.slice(0, 6).map((search, index) => (
              <Link
                href={`/search?keyword=${encodeURIComponent(search)}`}
                onClick={() => {
                  setShowSearchPreview(false);
                }}
                className="flex w-full flex-row items-center justify-between truncate whitespace-nowrap rounded-[1rem] px-5 py-4 scrollbar-none hover:cursor-pointer hover:bg-brand-lightest-gray sm:gap-x-4 lg:whitespace-normal"
                key={`${search}-${index}`}
              >
                <span className="mr-[1rem] flex grow">{`${search}`}</span>
              </Link>
            ))
          ) : !!userDoc ? (
            <p>No search history yet!</p>
          ) : (
            <div className="mt-4">
              <Button
                href="/signup"
                height="small"
                width="small"
                type="secondary"
                text="Sign up to see search history"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const CustomHits = (props: CustomHitsProps<any>) => {
  const { hits, sendEvent } = useHits(props);

  return (
    <>
      {hits.length !== 0 ? (
        <div className="flex flex-col gap-y-[.3rem]">
          {hits.map((hit, index) => (
            <props.hitComponent sendEvent={sendEvent} key={index} hit={hit} />
          ))}
        </div>
      ) : (
        <div className="m-[1rem] flex justify-center rounded-2xl bg-brand-lightest-gray p-[2rem]">
          <span>
            No matches found for your search query. Please try another keyword.
          </span>
        </div>
      )}
    </>
  );
};

const HitCardStyle =
  'relative flex cursor-pointer select-none items-center py-3 lg:pl-[2rem] pr-4 hover:bg-brand-lightest-gray rounded-2xl w-full pl-6 sm:pl-0';

interface CustomHitProps<THit extends BaseHit> {
  hit: SearchHit<THit>;
  sendEvent: (type: string, hit: SearchHit<THit>, eventName: string) => void;
}

function SellerHit({ hit, sendEvent }: CustomHitProps<any>) {
  return (
    <>
      <div
        key={hit.uid}
        className={HitCardStyle}
        onClick={() => {
          sendEvent('click', hit, 'seller hit clicked');
        }}
      >
        <Link
          href={'/profile/' + hit.username_slug}
          className="flex w-full items-center justify-between"
        >
          <div className="flex items-center gap-[1rem]">
            <Avatar user={hit} size="extraSmall" />

            <div className="flex flex-col">
              <Highlight
                attribute="username"
                hit={hit}
                className="block truncate text-[1.7rem] font-medium text-zinc-600"
              />
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}

const Suggestions = ({
  setShowSearchPreview,
}: {
  setShowSearchPreview: (val: boolean) => void;
}) => {
  const { searchTerm } = useSearch();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { suggestions } = useQuerySuggestions(debouncedSearchTerm ?? '');
  const { userDoc } = useAuth();
  return (
    <div className="mt-4 flex w-full items-center scrollbar-none lg:min-w-[50rem] lg:px-4">
      <div className="flex w-full flex-col">
        <div className="flex flex-col">
          {suggestions.map((search, index) => (
            <Link
              key={`${search.document.q}-${index}`}
              href={`/search?keyword=${encodeURIComponent(search.document.q)}`}
              className="flex w-full flex-row items-center justify-between truncate whitespace-nowrap rounded-[1rem] px-5 py-4 scrollbar-none hover:cursor-pointer hover:bg-brand-lightest-gray sm:gap-x-4 lg:whitespace-normal"
              onClick={() => {
                if (userDoc) {
                  saveSearchQuery({
                    uid: userDoc.uid,
                    query: search.document.q,
                  });
                }
                setShowSearchPreview(false);
              }}
            >
              <div className="hover w-[50rem]">
                {search.highlight.q?.snippet ? (
                  <div
                    className="highlighted-search flex w-[50rem] flex-nowrap items-center gap-x-2 truncate text-ellipsis whitespace-nowrap sm:w-[90rem]"
                    dangerouslySetInnerHTML={{
                      __html: search.highlight.q.snippet,
                    }}
                    key={`${search.document.q}-${index}`}
                  ></div>
                ) : (
                  <div
                    className="flex w-[50rem] flex-nowrap items-center gap-x-2 truncate whitespace-nowrap sm:w-[90rem]"
                    key={`${search.document.q}-${index}`}
                  >
                    {search.document.q}
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

const SearchResultsPreview = ({
  setShowSearchPreview,
}: {
  setShowSearchPreview: (val: boolean) => void;
}) => {
  const { debouncedSearchTerm } = useSearch();
  const { refine } = useSearchBox();
  const { userDoc } = useAuth();

  const searchHistory = useSearchHistory({
    uid: userDoc?.uid,
  });

  useEffect(() => {
    if (debouncedSearchTerm) refine(debouncedSearchTerm);
  }, [debouncedSearchTerm, refine]);

  return (
    <>
      {debouncedSearchTerm ? (
        <MotionDiv
          initial={{ opacity: 0, y: -10, x: '-50%' }}
          animate={{ opacity: 1, y: 0, x: '-50%' }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          className="fixed left-1/2 top-0 z-[50] flex h-screen w-full flex-col overflow-x-hidden overflow-y-hidden rounded-2xl bg-white p-4 pt-[4rem] shadow-xl scrollbar-none sm:absolute sm:top-[1rem] sm:overflow-y-scroll sm:pt-0 lg:h-fit lg:w-[91.3rem]"
        >
          {Boolean(searchHistory.length) && (
            <div className="mt-4 flex flex-col gap-y-2 pt-[2rem] sm:hidden">
              <SectionTitle title="Recent Searches" />

              <LastSearchSuggestions
                searchHistory={searchHistory}
                setShowSearchPreview={setShowSearchPreview}
              />
            </div>
          )}

          <div className="mt-16">
            <div className="flex sm:hidden">
              <SectionTitle title="Suggestions" />
            </div>

            <Suggestions setShowSearchPreview={setShowSearchPreview} />
          </div>

          <div className="p-[1rem]">
            <SectionTitle title="Sellers" />
          </div>

          <Index indexName={'sellers'}>
            <CustomHits hitComponent={SellerHit} />
          </Index>
        </MotionDiv>
      ) : (
        <MotionDiv
          initial={{ opacity: 0, y: -10, x: '-50%' }}
          animate={{ opacity: 1, y: 0, x: '-50%' }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          className="fixed left-1/2 top-0 z-[50] flex h-screen w-full flex-col overflow-x-hidden overflow-y-hidden rounded-2xl bg-white p-4 pt-[4rem] shadow-xl scrollbar-none sm:absolute sm:top-[1rem] sm:overflow-y-scroll sm:pt-0 lg:h-fit lg:w-[91.3rem]"
        >
          {searchHistory.length ? (
            <div className="mt-4 flex flex-col gap-y-2 pt-[2rem] sm:hidden">
              <SectionTitle title="Recent Searches" />

              <LastSearchSuggestions
                searchHistory={searchHistory}
                setShowSearchPreview={setShowSearchPreview}
              />
            </div>
          ) : (
            <div className="flex h-[20rem] w-full items-center justify-center">
              <h3 className="text-brand text-[2rem] font-medium text-zinc-500">
                Search for anything
              </h3>
            </div>
          )}
        </MotionDiv>
      )}
    </>
  );
};

const SectionTitle = ({
  title,
  helperElement,
  hideHelperElement = false,
}: {
  title: string;
  helperElement?: React.ReactNode;
  hideHelperElement?: boolean;
}) => {
  if (helperElement && !hideHelperElement) {
    return (
      <div className="flex w-full flex-row items-center justify-between px-[1rem]">
        <p className="text-brand mb-1 block text-[1.8rem] font-semibold text-zinc-600">
          {title}
        </p>

        {helperElement}
      </div>
    );
  }

  return (
    <div className="w-full px-[1rem]">
      <p className="text-[1.3rem] font-bold uppercase text-zinc-500">{title}</p>
    </div>
  );
};

export default SearchResultsPreview;
